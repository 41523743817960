/* LESS Theme by Rob Garrison

 To create your own theme, modify the code below and run it through
 a LESS compiler, like this one: http://leafo.net/lessphp/editor.html
 or download less.js from http://lesscss.org/

 Test out this less file live at http://codepen.io/Mottie/pen/eqBbn

 */
/*** theme ***/
/*** fonts ***/
/*** color definitions ***/
/* for best results, only change the hue (120),
   leave the saturation (60%) and luminosity (75%) alone
   pick the color from here: http://hslpicker.com/#99E699 */
/* darken(@headerBackground, 10%); */
/* desaturate(@headerAsc, 5%); */
/* it might be best to match the document body background color here */
/* ajax error message (added to thead) */
/* becomes height using padding (so it's divided by 2) */
/* 20px should be slightly wider than the icon width to avoid overlap */
/* url(icons/loading.gif); */
/* zebra striping */
.allRows {
  background-color: #f2f2f2;
}
.evenRows {
  background-color: #fff;
}
/* hovered rows */
/* Columns widget */
/* saturate( darken( desaturate(@headerBackground, 10%), 10% ), 30%); */
/* Filter widget transition */
.filterWidgetTransition {
  -webkit-transition: line-height 0.1s ease;
  -moz-transition: line-height 0.1s ease;
  -o-transition: line-height 0.1s ease;
  transition: line-height 0.1s ease;
}
/*** Arrows ***/
/* black */
/* white */
/* automatically choose the correct arrow/text color */
/* variable theme name - requires less.js 1.3+;
  or just replace (!".@{theme}") with the contents of @theme
 */
.tablesorter-travelport {
  background-color: #ffffff;
  margin: 0;
  width: 100%;
  text-align: left;
  /* style th's outside of the thead */
  /* style header */
  /* tfoot */
  /* optional disabled input styling */
  /* body */
  /* hovered row colors
	 you'll need to add additional lines for
	 rows with more than 2 child rows
	*/
  /* table processing indicator - indeterminate spinner */
  /* Column Widget - column sort colors */
  /* caption (non-theme matching) */
  /* filter widget */
  /* hidden filter row */
  /* ajax error row */
}
.tablesorter-travelport th,
.tablesorter-travelport td {
  height: 34px;
}
  .tablesorter-travelport th,
  .tablesorter-travelport thead td {
    border-style: dotted;
    border-color: #ffffff;
    border-width: 0 0 0 1px;
    background-color: #4E6659;
    color: #ffffff;
    font-weight: normal;
    padding: 10px;
    position: static;
    text-transform: uppercase;
  }
.tablesorter-travelport th:first-child {
  border-left: 0;
}
.tablesorter-travelport tbody td,
.tablesorter-travelport tfoot th,
.tablesorter-travelport tfoot td {
  padding: 5px 10px;
}
.tablesorter-travelport .tablesorter-header {
  background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAAP///////yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center right;
  padding: 5px 20px 5px 10px;
  cursor: pointer;
}
.tablesorter-travelport .tablesorter-header.sorter-false {
  background-image: none;
  cursor: default;
  padding: 10px;
}
.tablesorter-travelport .tablesorter-headerAsc {
  background-color: #004173;
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
  color: #ffffff;
}
.tablesorter-travelport .tablesorter-headerDesc {
  background-color: #009ed9;
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAAP///////yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
  color: #ffffff;
}
.tablesorter-travelport tfoot .tablesorter-headerAsc,
.tablesorter-travelport tfoot .tablesorter-headerDesc {
  /* remove sort arrows from footer */
  background-image: none;
}
.tablesorter-travelport .disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: not-allowed;
}
.tablesorter-travelport tbody {
  /* Zebra Widget - row alternating colors */
}
.tablesorter-travelport tbody td {
  background-color: #f2f2f2;
}
.tablesorter-travelport tbody td span.slider {
  background: url(../img/marketplace-sprite.png) no-repeat -148px -30px;
  display: inline-block;
  width: 34px;
  height: 18px;
  vertical-align: text-top;
}
.tablesorter-travelport tbody td span.slider.on {
  background-position: -148px 0;
}
.tablesorter-travelport tbody td.hilite {
  background-color: #FFFFCC;
}
.tablesorter-travelport tbody tr.even td {
  background-color: #fff;
}
.tablesorter-travelport .tablesorter-processing {
  background-image: url('data:image/gif;base64,R0lGODlhFAAUAKEAAO7u7lpaWgAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh+QQBCgACACwAAAAAFAAUAAACQZRvoIDtu1wLQUAlqKTVxqwhXIiBnDg6Y4eyx4lKW5XK7wrLeK3vbq8J2W4T4e1nMhpWrZCTt3xKZ8kgsggdJmUFACH5BAEKAAIALAcAAAALAAcAAAIUVB6ii7jajgCAuUmtovxtXnmdUAAAIfkEAQoAAgAsDQACAAcACwAAAhRUIpmHy/3gUVQAQO9NetuugCFWAAAh+QQBCgACACwNAAcABwALAAACE5QVcZjKbVo6ck2AF95m5/6BSwEAIfkEAQoAAgAsBwANAAsABwAAAhOUH3kr6QaAcSrGWe1VQl+mMUIBACH5BAEKAAIALAIADQALAAcAAAIUlICmh7ncTAgqijkruDiv7n2YUAAAIfkEAQoAAgAsAAAHAAcACwAAAhQUIGmHyedehIoqFXLKfPOAaZdWAAAh+QQFCgACACwAAAIABwALAAACFJQFcJiXb15zLYRl7cla8OtlGGgUADs=');
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1000;
}
.tablesorter-travelport tr.odd td.primary {
  background-color: #004fa6;
}
.tablesorter-travelport td.primary,
.tablesorter-travelport tr.even td.primary {
  background-color: #0068d9;
}
.tablesorter-travelport tr.odd td.secondary {
  background-color: #0068d9;
}
.tablesorter-travelport td.secondary,
.tablesorter-travelport tr.even td.secondary {
  background-color: #0074f3;
}
.tablesorter-travelport tr.odd td.tertiary {
  background-color: #0074f3;
}
.tablesorter-travelport td.tertiary,
.tablesorter-travelport tr.even td.tertiary {
  background-color: #0d81ff;
}
.tablesorter-travelport caption {
  background: #ffffff;
}
.tablesorter-travelport .tablesorter-filter-row input,
.tablesorter-travelport .tablesorter-filter-row select {
  width: 98%;
  height: auto;
  margin: 0;
  padding: 10px;
  color: #333333;
  background: #ffffff;
  border: 1px solid #bbbbbb;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: line-height 0.1s ease;
  -moz-transition: line-height 0.1s ease;
  -o-transition: line-height 0.1s ease;
  transition: line-height 0.1s ease;
}
.tablesorter-travelport .tablesorter-filter-row td {
  background: #eeeeee;
  line-height: normal;
  text-align: center;
  /* center the input */
  -webkit-transition: line-height 0.1s ease;
  -moz-transition: line-height 0.1s ease;
  -o-transition: line-height 0.1s ease;
  transition: line-height 0.1s ease;
}
.tablesorter-travelport .tablesorter-filter-row.hideme td {
  padding: 2px;
  margin: 0;
  line-height: 0;
  cursor: pointer;
}
.tablesorter-travelport .tablesorter-filter-row.hideme .tablesorter-filter {
  height: 1px;
  min-height: 0;
  border: 0;
  padding: 0;
  margin: 0;
  /* don't use visibility: hidden because it disables tabbing */
  opacity: 0;
  filter: alpha(opacity=0);
}
.tablesorter-travelport .tablesorter-errorRow td {
  cursor: pointer;
  background-color: #e6bf99;
}
.tablesorter-travelport td.data-td-button {
  white-space: nowrap;
}
.tablesorter-container {
  max-height: 412px;
  overflow-y: auto;
}
