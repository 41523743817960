.commandBtn-add {
  position: absolute;
  bottom: 30px;
  margin-right: auto;
  left: 0;
  right: 0;
}

.btn_add {
  background-color: rgb(120, 190, 32);
  -webkit-box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 12px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  -webkit-text-decoration-color: rgb(255, 255, 255);
  -webkit-text-decoration-line: none;
  -webkit-text-decoration-style: solid;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-transform: uppercase;
}
.btn-link {
  color: black;
  font-weight: bold;
}
.commandBtn-add .btn-link:focus,
.btn-link:hover {
  text-decoration: none;
  color: black;
}

.tablesorter {
  tbody {
    height: 100px;
    overflow-y: auto;
    overflow-x: auto;
  }
}

#manage-admins-table > .panel-default > .table-responsive > table.table {
  table-layout: unset !important;
}

#manage-admins-table > .panel-default > .table-responsive > table.table > thead,
#manage-admins-table > .panel-default > .table-responsive > table.table > tbody,
#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > thead
  > tr,
#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > tbody
  > tr,
#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > thead
  > th,
#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > tbody
  > th,
#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > thead
  > td,
#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > tbody
  > td {
  display: block;
}

#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > thead
  > tr {
  width: 97%;
  width: -webkit-calc(100%);
  width: -moz-calc(100%);
  width: calc(100%);
}

#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > thead
  > tr:after,
#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > tbody
  > tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > tbody {
  height: 100px;
  overflow-y: auto;
  overflow-x: auto;
}

#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > tbody
  td,
#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > thead
  th {
  width: 25% !important;
  float: left;
}

#manage-admins-table
  > .panel-default
  > .table-responsive
  > table.table
  > tbody
  .td-no-content {
  width: 100% !important ;
}

#manage-admins-table.no-content
  > .panel-default
  > .table-responsive
  > table.table
  > tbody {
  height: 41px;
  overflow-y: auto;
  overflow-x: auto;
}

.Select.travelport-select-manage-admins {
  overflow: visible;
  position: absolute;
  width: 197px;
}
